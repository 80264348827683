// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.class-students {
    margin: 1rem;
}

.student-card{
    margin: 0.5rem;
    padding: 0.5rem;             /* p-4 : remplissage de 4 unités (1rem) */
    display: flex; /* Active Flexbox */
    align-items: center; /* Centre les éléments verticalement */
    gap: 0.25rem;
    border-radius: 0.375rem;   /* rounded : bordures arrondies */
    cursor: pointer;            /* cursor-pointer : pointeur lors du survol */
    background-color: var(--color-background-secondary);
    justify-content: space-between;
}

.student-card:hover {
    background-color: var(--color-background);
}

.student-main-info{
    min-width: 240px;
}

.results-info{
    display: flex; /* Active Flexbox */
    align-items: center; /* Centre les éléments verticalement */
    gap: 2rem;
    width: 100%;
}

.attempts-nb{
    display: flex; /* Active Flexbox */
    align-items: center; /* Centre les éléments verticalement */
    min-width: 30px ;
}

/* Media query pour basculer en colonne sur petit écran */
@media (max-width: 560px) {
    .student-card {
        flex-direction: column; /* Empile les éléments en colonne */
        align-items: flex-start; /* Aligne à gauche */
    }}`, "",{"version":3,"sources":["webpack://./src/components/Teacher/Classes/Classes.css"],"names":[],"mappings":"AAAA;IACI,YAAY;AAChB;;AAEA;IACI,cAAc;IACd,eAAe,cAAc,yCAAyC;IACtE,aAAa,EAAE,mBAAmB;IAClC,mBAAmB,EAAE,sCAAsC;IAC3D,YAAY;IACZ,uBAAuB,IAAI,iCAAiC;IAC5D,eAAe,aAAa,6CAA6C;IACzE,mDAAmD;IACnD,8BAA8B;AAClC;;AAEA;IACI,yCAAyC;AAC7C;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,aAAa,EAAE,mBAAmB;IAClC,mBAAmB,EAAE,sCAAsC;IAC3D,SAAS;IACT,WAAW;AACf;;AAEA;IACI,aAAa,EAAE,mBAAmB;IAClC,mBAAmB,EAAE,sCAAsC;IAC3D,gBAAgB;AACpB;;AAEA,yDAAyD;AACzD;IACI;QACI,sBAAsB,EAAE,mCAAmC;QAC3D,uBAAuB,EAAE,oBAAoB;IACjD,CAAC","sourcesContent":[".class-students {\n    margin: 1rem;\n}\n\n.student-card{\n    margin: 0.5rem;\n    padding: 0.5rem;             /* p-4 : remplissage de 4 unités (1rem) */\n    display: flex; /* Active Flexbox */\n    align-items: center; /* Centre les éléments verticalement */\n    gap: 0.25rem;\n    border-radius: 0.375rem;   /* rounded : bordures arrondies */\n    cursor: pointer;            /* cursor-pointer : pointeur lors du survol */\n    background-color: var(--color-background-secondary);\n    justify-content: space-between;\n}\n\n.student-card:hover {\n    background-color: var(--color-background);\n}\n\n.student-main-info{\n    min-width: 240px;\n}\n\n.results-info{\n    display: flex; /* Active Flexbox */\n    align-items: center; /* Centre les éléments verticalement */\n    gap: 2rem;\n    width: 100%;\n}\n\n.attempts-nb{\n    display: flex; /* Active Flexbox */\n    align-items: center; /* Centre les éléments verticalement */\n    min-width: 30px ;\n}\n\n/* Media query pour basculer en colonne sur petit écran */\n@media (max-width: 560px) {\n    .student-card {\n        flex-direction: column; /* Empile les éléments en colonne */\n        align-items: flex-start; /* Aligne à gauche */\n    }}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
