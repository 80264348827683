// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `  
  /* Indicateur dans le Checkbox */
  .checkbox-indicator {
    display: flex; /* flex */
    align-items: center; /* items-center */
    justify-content: center; /* justify-center */
  
    /* Couleur actuelle (hérite de color du parent) */
    color: currentColor; /* text-current */
  }
  
  /* Icône Check */
  .checkbox-check {
    height: 1rem; /* h-4 */
    width: 1rem; /* w-4 */
    color: var(--color-primary);
  }
  `, "",{"version":3,"sources":["webpack://./src/components/Student/components/checkbox.css"],"names":[],"mappings":";EACE,gCAAgC;EAChC;IACE,aAAa,EAAE,SAAS;IACxB,mBAAmB,EAAE,iBAAiB;IACtC,uBAAuB,EAAE,mBAAmB;;IAE5C,iDAAiD;IACjD,mBAAmB,EAAE,iBAAiB;EACxC;;EAEA,gBAAgB;EAChB;IACE,YAAY,EAAE,QAAQ;IACtB,WAAW,EAAE,QAAQ;IACrB,2BAA2B;EAC7B","sourcesContent":["  \n  /* Indicateur dans le Checkbox */\n  .checkbox-indicator {\n    display: flex; /* flex */\n    align-items: center; /* items-center */\n    justify-content: center; /* justify-center */\n  \n    /* Couleur actuelle (hérite de color du parent) */\n    color: currentColor; /* text-current */\n  }\n  \n  /* Icône Check */\n  .checkbox-check {\n    height: 1rem; /* h-4 */\n    width: 1rem; /* w-4 */\n    color: var(--color-primary);\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
