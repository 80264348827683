import React from "react"
import Lottie from "lottie-react"
import loadingAnimation from "../../../../../assets/loading.json"


interface LoadingOverlayProps {
  message?: string
}

console.log('Loading animation data:', loadingAnimation); // Add this line to debug

export function LoadingOverlay({ message = "Génération en cours..." }: LoadingOverlayProps) {
  return (
    <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-12 flex flex-col items-center max-w-md mx-4">
        <div className="w-64 h-64"> {/* Increased from w-32 h-32 */}
          <Lottie
            animationData={loadingAnimation}
            loop={true}
            autoplay={true}
            style={{ width: '100%', height: '100%' }}
          />
        </div>
        <p className="mt-6 text-[#010f44] text-lg font-medium text-center">
          {message}
        </p>
      </div>
    </div>
  )
}