import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BACKEND_URL } from '../../config';
import { ChevronLeft, Check, Minus, X } from 'lucide-react';
import { LucideAArrowDown } from 'lucide-react';
import '../././Question.css';
import ProgressBar from './components/ProgressBar';

interface Answer {
  answer: string;
  isTrue: boolean;
}

interface QuestionResponse {
  selected_answers: string[];
  is_correct: boolean;
}

interface Question {
  id: number;
  question: string;
  answers: Record<string, Answer>;
  student_response: QuestionResponse;
}

interface QuizData {
  questions: Question[];
  answers: any; // Define a more specific type if needed
  score: number; // Add this line
}

interface EvaluationDetailsProps {
  studentId: string;
  quizId: string;
  onBackClick: () => void;
}

const EvaluationDetails: React.FC<EvaluationDetailsProps> = ({ studentId, quizId, onBackClick }) => {
  const [quizData, setQuizData] = useState<QuizData | null>(null);

  useEffect(() => {
    const fetchQuizData = async () => {
      try {
        const response = await axios.get(`${BACKEND_URL}/backapp/student/previous-quiz`, {
          params: {
            student_user_id: studentId,
            quiz_id: quizId
          }
        });
        setQuizData(response.data);
      } catch (error) {
        console.error('Error fetching quiz data:', error);
      }
    };
  
    fetchQuizData();
  }, [studentId, quizId]);

  if (!quizData) {
    return <div>Loading...</div>;
  }

  const { questions, answers } = quizData;

  const getProgressBarColor = (score: number): string => {
    if (score > 60) return 'var(--color-success)';
    if (score > 30) return 'var(--color-warning)';
    return 'var(--color-danger)';
  };
return (
      <div className="container mx-auto px-4 py-8">
        <button onClick={onBackClick} className="button-clear">
          <ChevronLeft size={16} />
          Retour
        </button>
  
      <div className="background-primary">
        <h2>Score : {quizData.score/5}/20</h2>
        <ProgressBar 
          percentage={quizData.score} // Changed from progress to percentage
          color={getProgressBarColor(quizData.score)}
        />
      </div>
  
        {quizData.questions.map((question, index) => (
          <div key={question.id} className="question-container">
            <h3>Question {index + 1}</h3>
            <p className="question-text">{question.question}</p>
            
            <div className="answers-group-container">
              {Object.entries(question.answers).map(([key, value], index) => {
                const isSelected = question.student_response.selected_answers.includes(key);
                const isCorrect = value.isTrue;
                
                let bgColor = "bg-white";
                if (isSelected) {
                  bgColor = isCorrect ? "bg-[#d4edd6]" : "bg-[#f9d1cf]";
                } else if (isCorrect) {
                  bgColor = "bg-[#f3f9ff]";
                }

                let className = "answer-default";
                if (isSelected) {
                  className = isCorrect ? "answer-success" : "answer-danger";
                } else if (isCorrect) {
                  className = "answer-neutral";
                }

                return (
                  <div 
                    key={key} 
                    className={className}
                  >
                    <div className="answer-container">
                    <div className={`answer-number answer-number-${index + 1}`}>
                      {key}
                    </div>
                      <span className='answer-content-results'>{value.answer}</span>
                    </div>
                    {isSelected && (
                      isCorrect ? (
                        <Check 
                          size={20}
                          className="icon-correct" 
                        />
                      ) : (
                        <X 
                          size={20}
                          className="icon-incorrect" 
                        />
                      )
                    )}
                  </div>
                );
              })}
            </div>
            
            <div>
              {question.student_response.is_correct ? (
                <div className="results-text-success">
                  <Check 
                    size={16}
                  />
                  <span>Réponse correcte</span>
                </div>
              ) : (
                <div className="results-text-danger">
                  <X 
                    size={16}
                  />
                  <span>Réponse incorrecte</span>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    );
  };
  
  export default EvaluationDetails;