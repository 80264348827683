import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from './Teacher/Evaluation/components/ui/dialog';
import { Button } from './Teacher/Evaluation/components/ui/button';

const LogoutModal = ({ show, onConfirm, onCancel }) => {
  return (
    <Dialog open={show} onOpenChange={onCancel}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle className="text-2xl font-bold text-[#010f44]">
            Confirmer la déconnexion
          </DialogTitle>
        </DialogHeader>
        <div className="py-4">
          <p className="text-sm text-gray-600">
            Voulez-vous vraiment vous déconnecter ?
          </p>
        </div>
        <DialogFooter className="sm:justify-start">
          <Button
            type="button"
            variant="secondary"
            onClick={onCancel}
            className="bg-[#e7f2ff] text-[#1550a7] hover:bg-[#e7f2ff]/80"
          >
            Annuler
          </Button>
          <Button
            type="button"
            onClick={onConfirm}
            className="bg-[#1550a7] text-white hover:bg-[#1550a7]/90"
          >
            Déconnecter
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default LogoutModal;