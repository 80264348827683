import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Profile from './Teacher/Profile/Profile';
import EvaluationsPage from './Teacher/Evaluation/page/page';
import EvaluationSetup from './Teacher/Evaluation/page/new/page';
import EvaluationGeneration from './Teacher/Evaluation/page/generate/page';
import ManualCreationPage from './Teacher/Evaluation/page/manual/page';
import AIGenerationPage from './Teacher/Evaluation/page/ai/page';
import ResultsPage from './Teacher/Evaluation/page/results/page';
import Classes from './Teacher/Classes/Classes';
import SupportPage from './Support/SupportPage';

const TeacherRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Profile />} />
      <Route path="/help" element={<SupportPage />} />
      <Route path="/profile" element={<Profile />} />
      <Route path="/evaluations" element={<EvaluationsPage />} />
      <Route path="/evaluation/new" element={<EvaluationSetup />} />
      <Route path="/evaluation/generate" element={<EvaluationGeneration />} />
      <Route path="/evaluation/manual" element={<ManualCreationPage />} />
      <Route path="/evaluation/ai/:id" element={<AIGenerationPage />} />
      <Route path="/evaluation/results/:id" element={<ResultsPage />} />
      <Route path="/classes" element={<Classes />} />
    </Routes>
  );
};

export default TeacherRoutes;