import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate} from 'react-router-dom';
import Sidebar from './components/Sidebar';
import StudentRoutes from './components/StudentRoute';
import TeacherRoutes from './components/TeacherRoute';
import Login from './components/Login';

function App() {
  const [userType, setUserType] = useState(localStorage.getItem('userType'));
  const [isLoading, setIsLoading] = useState(true);
  
  useEffect(() => {
    const storedUserType = localStorage.getItem('userType');
    if (storedUserType) {
      setUserType(storedUserType);
    }
    setIsLoading(false);
  }, []);

  const handleLogin = (type) => {
    setUserType(type);
    localStorage.setItem('userType', type);
  };

  const handleLogout = () => {
    localStorage.clear();
    setUserType(null);
  };
  
  if (isLoading) {
    return null;
  }

  const userFirstName = localStorage.getItem('userFirstName');
  const userLastName = localStorage.getItem('userLastName');
  const userName = `${userFirstName} ${userLastName}`;

  return (
    <Router>
      <div className="flex h-screen">
        {userType ? (
          <>
            <Sidebar
              userType={userType}
              onLogout={handleLogout}
            />
            <div className="flex-1 overflow-y-auto">
              <Routes>
                {userType === 'student' ? (
                  <Route path="/*" element={<StudentRoutes/>} />
                ) : (
                  <Route path="/*" element={<TeacherRoutes/>} />
                )}
              </Routes>
            </div>
          </>
        ) : (
          <Routes>
            <Route path="/login" element={<Login onLogin={handleLogin} />} />
            <Route path="*" element={<Navigate to="/login" />} />
          </Routes>
        )}
      </div>
    </Router>
  );
}

export default App;