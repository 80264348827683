import { ChevronLeft, ChevronRight, Check, X } from 'lucide-react';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BACKEND_URL } from '../../config';

interface Answer {
  answer: string;
  isTrue: boolean;
}

interface Question {
  id: number;
  question: string;
  answers: Record<string, Answer>;
}

interface PracticeQuestionsProps {
  notion: string;
  notion_id: number;
  onBackClick: () => void;
}

const PracticeQuestions: React.FC<PracticeQuestionsProps> = ({ notion, notion_id, onBackClick }) => {
  const [questions, setQuestions] = useState<Question[]>([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedAnswers, setSelectedAnswers] = useState<string[]>([]);
  const [showCorrectAnswers, setShowCorrectAnswers] = useState(false);


  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        const response = await axios.get(`${BACKEND_URL}/backapp/student/recommend_questions`, {
          params: {
            notion_id: notion_id,
            number_of_questions: 3
          }
        });
        setQuestions(response.data);
      } catch (error) {
        console.error('Error fetching questions:', error);
      }
    };

    fetchQuestions();
  }, [notion_id]);

  const handleAnswerChange = (answerKey: string) => {
    setSelectedAnswers((prevSelectedAnswers) => {
      const newAnswers = prevSelectedAnswers.includes(answerKey)
        ? prevSelectedAnswers.filter((key) => key !== answerKey)
        : [...prevSelectedAnswers, answerKey];
      return newAnswers;
    });
  };

  const handleValidate = () => {
    setShowCorrectAnswers(true);
  };

  const handleNextQuestion = () => {
    setSelectedAnswers([]);
    setShowCorrectAnswers(false);
    setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
  };

  const handlePreviousQuestion = () => {
    setSelectedAnswers([]);
    setShowCorrectAnswers(false);
    setCurrentQuestionIndex((prevIndex) => prevIndex - 1);
  };

  if (questions.length === 0) {
    return <div>Loading...</div>;
  }

  const currentQuestion = questions[currentQuestionIndex];

  return (
    <div>
      <button onClick={onBackClick} className="button-clear">
        <ChevronLeft size={20} /> Retour
      </button>
      
      <div className="question-container">
        <p className='question-text'>{currentQuestion.question}</p>
        <div className="answers-group-container">
          {Object.entries(currentQuestion.answers).map(([key, value], index) => {
            // Move variable declarations inside a block
            const isSelected = selectedAnswers.includes(key);
            const isCorrect = value.isTrue;
  

            let className = "";
              if (showCorrectAnswers) {
                if (isSelected) {
                  className = isCorrect ? "answer-success" : "answer-danger";
                } else if (isCorrect) {
                  className = "answer-neutral";
                } else className = "answer-results-invisible"
              } else className = "answer-results-invisible"

            return (
              <div 
                key={key} 
                className={className}
              >
                  
                  <div className={`answer-number answer-number-${index + 1}`}>
                    {String.fromCharCode(65 + index)}
                  </div>               
                  <label
                      className={`answer-content 
                        ${showCorrectAnswers ? '' : (isSelected && !showCorrectAnswers ? 'checked' : 'not-shown')}`}
                       
                  >
                    {value.answer}
                  </label>
                  {!showCorrectAnswers && (
                    <input
                      type="checkbox"
                      checked={isSelected}
                      onChange={() => handleAnswerChange(key)}
                    />
                  )}

                {showCorrectAnswers && isSelected && (
                  isCorrect ? (
                    <Check 
                      size={20}
                      className="icon-correct"
                    />
                  ) : (
                    <X 
                      size={20}
                      className="icon-incorrect"
                    />
                  )
                )}
              </div>
            );
          })}
          
        </div>
        
        <div className="duo-buttons">
          {currentQuestionIndex > 0 && (
            <button onClick={handlePreviousQuestion} className="button-outline">
              <ChevronLeft size={20} />Question précédente
            </button>
          )}
          {currentQuestionIndex < questions.length - 1 && (
            <button onClick={handleNextQuestion} className="button-outline">
              <ChevronRight size={20} />Question suivante
            </button>
          )}
          {!showCorrectAnswers && (
            <button onClick={handleValidate} className="button-primary">
              Valider
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default PracticeQuestions;