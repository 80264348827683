// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input {
    width: 100%; /* w-full */
    padding: 0.5rem; /* p-2 (équivalent à 8px si 1rem = 16px) */
    border: 1px solid var(--color-clear); /* border-gray-300 */
    border-radius: 0.375rem; /* rounded */
    font-size: 16px;
    color: var(--color-text);
    background-color: #ffffff; /* Fond blanc */
    box-sizing: border-box; /* Inclure les bordures dans la largeur totale */

  }

  .input:focus {
    outline: none; /* Supprimer la bordure bleue par défaut */
    border-color: var(--color-primary); /* Couleur de bordure au focus */
    box-shadow: 0 0 0 1px var(--color-primary); /* Anneau autour de l'élément */
  }

.checkbox{
  border: 1px solid var(--color-danger);
}
`, "",{"version":3,"sources":["webpack://./src/components/Input.css"],"names":[],"mappings":"AAAA;IACI,WAAW,EAAE,WAAW;IACxB,eAAe,EAAE,0CAA0C;IAC3D,oCAAoC,EAAE,oBAAoB;IAC1D,uBAAuB,EAAE,YAAY;IACrC,eAAe;IACf,wBAAwB;IACxB,yBAAyB,EAAE,eAAe;IAC1C,sBAAsB,EAAE,gDAAgD;;EAE1E;;EAEA;IACE,aAAa,EAAE,0CAA0C;IACzD,kCAAkC,EAAE,gCAAgC;IACpE,0CAA0C,EAAE,+BAA+B;EAC7E;;AAEF;EACE,qCAAqC;AACvC","sourcesContent":[".input {\n    width: 100%; /* w-full */\n    padding: 0.5rem; /* p-2 (équivalent à 8px si 1rem = 16px) */\n    border: 1px solid var(--color-clear); /* border-gray-300 */\n    border-radius: 0.375rem; /* rounded */\n    font-size: 16px;\n    color: var(--color-text);\n    background-color: #ffffff; /* Fond blanc */\n    box-sizing: border-box; /* Inclure les bordures dans la largeur totale */\n\n  }\n\n  .input:focus {\n    outline: none; /* Supprimer la bordure bleue par défaut */\n    border-color: var(--color-primary); /* Couleur de bordure au focus */\n    box-shadow: 0 0 0 1px var(--color-primary); /* Anneau autour de l'élément */\n  }\n\n.checkbox{\n  border: 1px solid var(--color-danger);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
