import { useState, useEffect } from "react"
import { useParams } from 'react-router-dom';
import { Folder, Users } from 'lucide-react'
import { Button } from "../../components/ui/button"
import { Card } from "../../components/ui/card"
import type { EvaluationResult } from "../../types/results"
import { StudentSelector } from "../../components/student-selector"
import { StudentResultView } from "../../components/student-result-view"
import type { StudentResult } from "../../types/student-results"
import axios from 'axios'
import { BACKEND_URL } from '../../../../../config'

interface Answer {
  answer: string;
  isTrue: boolean;
}

interface QuestionStats {
  question_id: number;
  question_text: string;
  answers: Record<string, Answer>;
  answer_stats: Record<string, number>;
  correct_percentage: number;
  attempt_count: number;
  total_students: number;
}

interface QuizInfo {
  quiz_id: string;
  name: string;
  theme: string;
  chapter: string;
  notion: string;
  shared_date: string;
  average_score: number;
  total_students: number;
  attempted_count: number;
  attempt_rate: number;
}

interface StudentAnswer {
  selected_answers: string[];
  is_correct: boolean;
}

interface StudentStats {
  name: string;
  class: {
    id: number;
    name: string;
  };
  attempted: boolean;
  score: number | null;
  answers: Record<string, {
    selected_answers: StudentAnswer | [];
    is_correct: boolean;
    correct_answers: string[];
  }>;
}

interface QuizResults {
  quiz_info: QuizInfo;
  questions_stats: QuestionStats[];
  student_stats: StudentStats[];
}

export default function ResultsPage() {
  const [results, setResults] = useState<QuizResults | null>(null);
  const [view, setView] = useState<"general" | "student">("general");
  const [selectedStudentId, setSelectedStudentId] = useState<string>("");
  const { id } = useParams();

  useEffect(() => {
    const fetchResults = async () => {
      try {
        const response = await axios.get(`${BACKEND_URL}/backapp/teacher/quiz-results`, {
          params: {
            quizz_id: id
          }
        });
        setResults(response.data);
        // Set first attempted student as selected by default
        const firstAttemptedStudent = response.data.student_stats.find(
          (student: StudentStats) => student.attempted
        );
        if (firstAttemptedStudent) {
          setSelectedStudentId(firstAttemptedStudent.name);
        }
      } catch (error) {
        console.error('Error fetching quiz results:', error);
      }
    };

    if (id) {
      fetchResults();
    }
  }, [id]);

  

  if (!results) return null;

  const selectedStudent = results.student_stats.find(s => s.name === selectedStudentId);

  const handlePreviousStudent = () => {
    const currentIndex = results.student_stats.findIndex(s => s.name === selectedStudentId);
    if (currentIndex > 0) {
      setSelectedStudentId(results.student_stats[currentIndex - 1].name);
    }
  };
  
  const handleNextStudent = () => {
    const currentIndex = results.student_stats.findIndex(s => s.name === selectedStudentId);
    if (currentIndex < results.student_stats.length - 1) {
      setSelectedStudentId(results.student_stats[currentIndex + 1].name);
    }
  };

  return (
    <div className="container mx-auto py-8 px-4">
      <Card className="bg-[#e7f2ff] p-6 mb-8">
        <div className="space-y-4">
          <h1 className="text-3xl font-bold text-[#010f44]">{results.quiz_info.name}</h1>
          <p className="text-sm text-gray-500">{new Date(results.quiz_info.shared_date).toLocaleDateString()}</p>

          <div className="flex flex-wrap gap-2">
            <span className="bg-white px-3 py-1 rounded-full text-sm">
              {results.quiz_info.theme}
            </span>
            <span className="bg-white px-3 py-1 rounded-full text-sm">
              {results.quiz_info.chapter}
            </span>
            <span className="bg-white px-3 py-1 rounded-full text-sm">
              {results.quiz_info.notion}
            </span>
          </div>
        </div>
      </Card>

      <div className="flex gap-4 mb-8">
        <Button
          variant={view === "general" ? "default" : "outline"}
          className={
            view === "general"
              ? "bg-[#1550a7] hover:bg-[#1550a7]/90"
              : "border-[#1550a7] text-[#1550a7]"
          }
          onClick={() => setView("general")}
        >
          <Folder className="mr-2 h-4 w-4" />
          Vue générale
        </Button>
        <Button
          variant={view === "student" ? "default" : "outline"}
          className={
            view === "student"
              ? "bg-[#1550a7] hover:bg-[#1550a7]/90"
              : "border-[#1550a7] text-[#1550a7]"
          }
          onClick={() => setView("student")}
        >
          <Users className="mr-2 h-4 w-4" />
          Vue par élève
        </Button>
      </div>

      {view === "general" && (
  <div className="space-y-8">
    {results.questions_stats.map((question: QuestionStats, index: number) => (
      <Card key={question.question_id} className="p-6">
        <div className="space-y-6">
          <div>
            <h2 className="text-xl font-bold text-[#010f44] mb-2">
              Question {index + 1}
            </h2>
            <p className="text-gray-800">{question.question_text}</p>
          </div>

          <div className="space-y-4">
            {Object.entries(question.answers).map(([key, answer], ansIndex) => (
              <div key={key} className="flex items-center gap-4">
                <div
                  className={`w-8 h-8 rounded-full flex items-center justify-center text-white ${
                    ansIndex === 0
                      ? "bg-[#caadfa]"
                      : ansIndex === 1
                      ? "bg-[#73c57b]"
                      : ansIndex === 2
                      ? "bg-[#fed061]"
                      : "bg-[#ec6963]"
                  }`}
                >
                  {key}
                      </div>
                      <div className="flex-1">
                        <div className="flex justify-between mb-1">
                          <span className="text-sm">{answer.answer}</span>
                          <span className="text-sm font-medium">
                            {question.answer_stats[key]}% {/* Use answer_stats from QuestionStats instead of answer.percentage */}
                          </span>
                        </div>
                        <div className="h-2 bg-gray-100 rounded-full overflow-hidden">
                          <div
                            className={`h-full ${
                              answer.isTrue ? "bg-[#73c57b]" : "bg-[#ec6963]"
                            }`}
                            style={{ width: `${question.answer_stats[key]}%` }}
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 pt-4 border-t">
                <div className="space-y-2">
                  <div className="text-sm text-gray-600">% de points obtenus en moyenne</div>
                  <div className="flex items-center gap-4">
                    <div className="flex-1 h-6 bg-gray-100 rounded-full overflow-hidden">
                      <div
                        className="h-full bg-[#fee095]"
                        style={{ width: `${question.correct_percentage}%` }}
                      />
                    </div>
                    <span className="text-sm font-medium">{question.correct_percentage}%</span>
                  </div>
                </div>

                <div className="space-y-2">
                  <div className="text-sm text-gray-600">% d'élèves ayant participé</div>
                  <div className="flex items-center gap-4">
                    <div className="flex-1 h-6 bg-gray-100 rounded-full overflow-hidden">
                      <div
                        className="h-full bg-[#dbc6fc]"
                        style={{ width: `${(question.attempt_count / results.quiz_info.total_students) * 100}%` }}
                      />
                    </div>
                    <span className="text-sm font-medium">
                      {((question.attempt_count / results.quiz_info.total_students) * 100).toFixed(1)}%
                    </span>
                  </div>
                </div>
                </div>
              </div>
            </Card>
          ))}
        </div>
      )}

      {view === "student" && selectedStudent && (
        <>
          <StudentSelector
            students={results.student_stats.map(s => ({ id: s.name, name: s.name }))}
            selectedStudentId={selectedStudentId}
            onSelectStudent={setSelectedStudentId}
            onPreviousStudent={handlePreviousStudent}
            onNextStudent={handleNextStudent}
          />
          <StudentResultView
            student={{
              id: selectedStudent.name,
              name: selectedStudent.name,
              successRate: selectedStudent.score || 0,
              timeSpent: "N/A",
              score: selectedStudent.score || 0,
              maxScore: 100,
              answers: Object.entries(selectedStudent.answers).map(([id, data]) => {
                // Get selected answers array
                const selectedAnswers = Array.isArray(data.selected_answers) 
                  ? [] // Empty array for unanswered questions
                  : data.selected_answers.selected_answers; // Array of selected answer keys (e.g. ["A", "B"])
                
                return {
                  questionId: parseInt(id),
                  selectedAnswers, // Pass the array of selected answers
                  isCorrect: data.is_correct,
                  correctAnswers: data.correct_answers
                };
              })
            }}
            evaluation={{
              id: results.quiz_info.quiz_id,
              title: results.quiz_info.name,
              date: results.quiz_info.shared_date,
              theme: results.quiz_info.theme,
              chapter: results.quiz_info.chapter,
              notion: results.quiz_info.notion,
              difficulty: "medium",
              duration: "N/A",
              questions: results.questions_stats.map(q => ({
                id: q.question_id,
                question: q.question_text,
                answers: Object.entries(q.answers).map(([key, answer]) => ({
                  key, // Include the answer key (A, B, C, D)
                  text: answer.answer,
                  isCorrect: answer.isTrue
                }))
              }))
            }}
          />
        </>
      )}
    </div>
  )
}