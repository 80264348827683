import { Trash2 } from 'lucide-react'
import { Button } from './ui/button'
import { Card } from './ui/card'
import type { Evaluation } from '../types/evaluation'
import { useNavigate } from 'react-router-dom'

interface ClassType {
  id: number;
  name: string;
}

interface EvaluationCardProps {
  evaluation: Evaluation;
  onShare: (id: string) => void;
  onModify: (id: string) => void;
  onDelete: (id: string) => void;
  showProgress?: boolean;
}

export function EvaluationCard({ evaluation, onShare, onModify, onDelete, showProgress }: EvaluationCardProps) {
  const navigate = useNavigate()
  const handleModify = () => {
    navigate(`/evaluation/generate?id=${evaluation.id}`)
  }
  const handleExamine = () => {
    console.log('Examining evaluation:', evaluation.id);
    navigate(`/evaluation/results/${evaluation.id}`);
  }
  return (
    <div className="flex items-center justify-between py-3">
      <div className="flex-1">
        <div className="flex items-center gap-2">
          <h3 className="text-[#010f44] font-medium">{evaluation.title}</h3>
          {evaluation.classes?.map((class_: ClassType) => (
            <span 
              key={class_.id} 
              className="px-2 py-1 text-xs font-medium rounded-full bg-[#fee095]"
            >
              {class_.name}
            </span>
          ))}
        </div>
        <p className="text-sm text-gray-500">{evaluation.date}</p>
      </div>

      {showProgress && (
        <div className="flex items-center gap-2 mr-4">
          {(evaluation.studentCount ?? 0) > 0 ? (
            <>
              <div className="px-3 py-1 rounded-full bg-gray-100">
                {evaluation.studentCount}/{evaluation.totalStudents} élèves
              </div>
              <div className="px-3 py-1 rounded-full bg-gray-100">
                {/* Display overall average score */}
                Note: {Math.round(Number(evaluation.averageScore?.toFixed(1))) ?? "0.0"}/100
              </div>
            </>
          ) : (
            <div className="px-3 py-1 rounded-full bg-red-100 text-red-600 text-sm">
              0/{evaluation.totalStudents} participations
            </div>
          )}
        </div>
      )}

      <div className="flex gap-2">
        <Button
          variant="default"
          className="bg-[#1550a7] hover:bg-[#1550a7]/90"
          onClick={() => onShare(evaluation.id)}
        >
          Partager
        </Button>
        {showProgress ? (
          <Button
            variant="outline"
            className="border-[#1550a7] text-[#1550a7]"
            onClick={handleExamine}
          >
            Examiner
          </Button>
        ) : (
          <Button
            variant="outline"
            className="border-[#1550a7] text-[#1550a7]"
            onClick={handleModify}
          >
            Modifier
          </Button>
        )}
        <Button
          variant="ghost"
          className="text-red-500 hover:text-red-600 hover:bg-red-50"
          onClick={() => onDelete(evaluation.id)}
        >
          <Trash2 className="h-4 w-4" />
        </Button>
      </div>
    </div>
  )
}
