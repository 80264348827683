import { useState } from "react"
import { X } from 'lucide-react'
import { Button } from "../components/ui/button"
import { Input } from "../components/ui/input"
import { Card } from "../components/ui/card"
import type { Question } from "../types/evaluation"
import { useNavigate } from "react-router-dom"

export function QuestionCreator() {
  const navigate = useNavigate()
  const [question, setQuestion] = useState<Question>({
    id: 1,
    title: "",
    theme: "Nombres et calculs", // Add theme property
    chapter: "Manipuler les nombres réels", // Add chapter property
    notion: "Ensemble des nombres réels", // Add notion property
    answers: Array(4).fill({ text: "", isCorrect: false }),
    correctAnswer: undefined,
  })

  const handleSave = () => {
    // Save question logic here
    console.log("Saving question:", question)
    navigate(-1)
  }

  return (
    <div className="container max-w-4xl mx-auto py-8">
      <Card className="bg-[#e7f2ff] p-6 mb-8">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-2xl font-bold text-[#010f44]">Question 1</h2>
          <Button variant="ghost" size="icon">
            <X className="h-4 w-4" />
          </Button>
        </div>

        <div className="flex flex-wrap gap-2 mb-6">
          <span className="bg-white/80 px-3 py-1 rounded-full text-sm">
            Thème - {question.theme}
          </span>
          <span className="bg-white/80 px-3 py-1 rounded-full text-sm">
            Chapitre - {question.chapter}
          </span>
          <span className="bg-white/80 px-3 py-1 rounded-full text-sm">
            Notion - {question.notion}
          </span>
        </div>

        <div className="space-y-6">
          <div>
            <Input
              placeholder="Écrire l'intitulé de la question..."
              value={question.title}
              onChange={(e) =>
                setQuestion({ ...question, title: e.target.value })
              }
              className="bg-white"
            />
          </div>

          <Button
            variant="outline"
            size="sm"
            className="text-[#1550a7] border-[#1550a7]"
          >
            + Ajouter un fichier
          </Button>

          <div className="space-y-4">
            {question.answers.map((answer, index) => (
              <div key={index} className="flex items-center gap-4">
                <div
                  className={`w-8 h-8 rounded-full flex items-center justify-center text-white ${
                    index === 0
                      ? "bg-[#8b5cf6]"
                      : index === 1
                      ? "bg-[#10b981]"
                      : index === 2
                      ? "bg-[#f59e0b]"
                      : "bg-[#ef4444]"
                  }`}
                >
                  {index + 1}
                </div>
                <Input
                  placeholder={`Écrire la réponse ${index + 1}...`}
                  value={answer.text} // Access the text property of the answer object
                  onChange={(e) => {
                    const newAnswers = [...question.answers]
                    newAnswers[index] = { ...newAnswers[index], text: e.target.value }
                    setQuestion({ ...question, answers: newAnswers })
                  }}
                  className="flex-1"
                />
                <input
                  type="checkbox"
                  checked={question.correctAnswer === index}
                  onChange={() =>
                    setQuestion({ ...question, correctAnswer: index })
                  }
                  className="w-5 h-5 rounded border-gray-300"
                />
              </div>
            ))}
          </div>
        </div>
      </Card>

      <div className="flex justify-end">
        <Button
          onClick={handleSave}
          className="bg-[#1550a7] hover:bg-[#1550a7]/90"
        >
          Enregistrer
        </Button>
      </div>
    </div>
  )
}