// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!../../App.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Chemin vers le fichier contenant les variables */

.body{
  margin-left: 2rem;
  margin-right: 2rem;
}

.user-profile-info-container{
  display: flex; /* Flexbox */
  align-items: center; /* Alignement vertical centré */
}

.profile-picture{
  width: 6rem; /* Largeur de 24 * 0.25rem = 6rem */
  height: 6rem; /* Hauteur de 24 * 0.25rem = 6rem */
  border-radius: 50%; /* Cercle parfait */
}

.avatar-container {
  width: 6rem; /* Largeur de 24 * 0.25rem = 6rem */
  height: 6rem; /* Hauteur de 24 * 0.25rem = 6rem */
  border-radius: 50%; /* Cercle parfait */
  background-color: var(--color-clear); /* Couleur de fond gris clair (équivalent bg-gray-300) */
  display: flex; /* Flexbox pour aligner le contenu */
  align-items: center; /* Centre verticalement */
  justify-content: center; /* Centre horizontalement */
  font-size: 1.875rem; /* Taille de police équivalente à text-3xl (30px) */
  font-weight: bold; /* Texte en gras */
  color: white; /* Couleur du texte gris foncé (équivalent text-gray-700) */
}`, "",{"version":3,"sources":["webpack://./src/components/Student/StudentProfile.css"],"names":[],"mappings":"AAAyB,mDAAmD;;AAE5E;EACE,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,aAAa,EAAE,YAAY;EAC3B,mBAAmB,EAAE,+BAA+B;AACtD;;AAEA;EACE,WAAW,EAAE,mCAAmC;EAChD,YAAY,EAAE,mCAAmC;EACjD,kBAAkB,EAAE,mBAAmB;AACzC;;AAEA;EACE,WAAW,EAAE,mCAAmC;EAChD,YAAY,EAAE,mCAAmC;EACjD,kBAAkB,EAAE,mBAAmB;EACvC,oCAAoC,EAAE,wDAAwD;EAC9F,aAAa,EAAE,oCAAoC;EACnD,mBAAmB,EAAE,yBAAyB;EAC9C,uBAAuB,EAAE,2BAA2B;EACpD,mBAAmB,EAAE,mDAAmD;EACxE,iBAAiB,EAAE,kBAAkB;EACrC,YAAY,EAAE,2DAA2D;AAC3E","sourcesContent":["@import '../../App.css'; /* Chemin vers le fichier contenant les variables */\n\n.body{\n  margin-left: 2rem;\n  margin-right: 2rem;\n}\n\n.user-profile-info-container{\n  display: flex; /* Flexbox */\n  align-items: center; /* Alignement vertical centré */\n}\n\n.profile-picture{\n  width: 6rem; /* Largeur de 24 * 0.25rem = 6rem */\n  height: 6rem; /* Hauteur de 24 * 0.25rem = 6rem */\n  border-radius: 50%; /* Cercle parfait */\n}\n\n.avatar-container {\n  width: 6rem; /* Largeur de 24 * 0.25rem = 6rem */\n  height: 6rem; /* Hauteur de 24 * 0.25rem = 6rem */\n  border-radius: 50%; /* Cercle parfait */\n  background-color: var(--color-clear); /* Couleur de fond gris clair (équivalent bg-gray-300) */\n  display: flex; /* Flexbox pour aligner le contenu */\n  align-items: center; /* Centre verticalement */\n  justify-content: center; /* Centre horizontalement */\n  font-size: 1.875rem; /* Taille de police équivalente à text-3xl (30px) */\n  font-weight: bold; /* Texte en gras */\n  color: white; /* Couleur du texte gris foncé (équivalent text-gray-700) */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
