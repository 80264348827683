"use client"

import { ChevronLeft, ChevronRight, ChevronDown } from 'lucide-react'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../components/ui/select"
import { Button } from "../components/ui/button"

interface StudentSelectorProps {
  students: { id: string; name: string }[]
  selectedStudentId: string
  onSelectStudent: (studentId: string) => void
  onPreviousStudent: () => void
  onNextStudent: () => void
}

export function StudentSelector({
  students,
  selectedStudentId,
  onSelectStudent,
  onPreviousStudent,
  onNextStudent,
}: StudentSelectorProps) {
  return (
    <div className="mb-8">
      <h2 className="text-gray-600 mb-2">Consulter la copie de l'élève</h2>
      <div className="flex items-center gap-2">
        <Button
          variant="ghost"
          size="icon"
          onClick={onPreviousStudent}
          className="text-[#1550a7]"
        >
          <ChevronLeft className="h-4 w-4" />
        </Button>
        
        <Select value={selectedStudentId} onValueChange={onSelectStudent}>
          <SelectTrigger className="flex-1">
            <SelectValue />
          </SelectTrigger>
          <SelectContent>
            {students.map((student) => (
              <SelectItem key={student.id} value={student.id}>
                {student.name}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>

        <Button
          variant="ghost"
          size="icon"
          onClick={onNextStudent}
          className="text-[#1550a7]"
        >
          <ChevronRight className="h-4 w-4" />
        </Button>
      </div>
    </div>
  )
}

