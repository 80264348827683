import React from 'react';
import './ProgressBar.css';

interface ProgressBarProps {
  percentage: number;
  color: string;
  label?: string;
}

const ProgressBar: React.FC<ProgressBarProps> = ({ label, percentage, color }) => {
  return (
    <div className="mb-4 w-full">
      <div className="flex justify-between mb-1">
        <span className='subtext'>{label}</span>
      </div>
      <div className="progress-bar-container">
        <div
          className="progress-bar-inside-bar"
          style={{ 
            width: `${percentage}%`, 
            backgroundColor: color,
          }}
        />
        <p className='percentage-text'>{percentage}%</p>
      </div>
    </div>
  );
};

export default ProgressBar;