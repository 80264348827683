import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { BACKEND_URL } from '../config';

const Login = ({ onLogin }) => {
  const [username_or_email, setUsernameOrEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleLogin = async () => {
    if (process.env.REACT_APP_LOCAL_DEV == 'true') {
      const userType = process.env.REACT_APP_USER_TYPE;
      console.log('Logging in as', userType);
      onLogin(userType);
      localStorage.setItem('userId', '1'); 
      localStorage.setItem('email', 'test@example.com'); 
      localStorage.setItem('userName', 'Test User'); 
      localStorage.setItem('userFirstName', 'First_name');
      localStorage.setItem('userLastName', 'Last_name');
      localStorage.setItem('schoolName', 'Test School'); 
      localStorage.setItem('userType', userType);
      navigate('/profile');
    } else {

      try {
        const response = await axios.post(`${BACKEND_URL}/backapp/login/`, {
          username_or_email,
          password,
        });
        console.log(response);
        if (response.data.userType) {
          onLogin(response.data.userType);
          localStorage.setItem('userId', response.data.userId);
          localStorage.setItem('email', response.data.userEmail);
          localStorage.setItem('userFirstName', response.data.userFirstName);
          localStorage.setItem('userLastName', response.data.userLastName);
          localStorage.setItem('schoolName', response.data.schoolName);
          localStorage.setItem('userType', response.data.userType);
          navigate('/');
        } else {
          setError('Password or Username/Email wrong');
        }
      } catch (error) {
        setError('Password or Username/Email wrong');
        console.error(error);
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleLogin();
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="bg-white p-8 rounded-lg shadow-md w-full max-w-md">
        <h1 className="text-3xl font-semibold mb-6 text-center" style={{ fontFamily: 'Paytone One, sans-serif' }}>Bienvenue chez Edjoy!</h1>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-gray-700" style={{ fontFamily: 'Paytone One, sans-serif' }}>Username or Email:</label>
            <input
              type="text"
              value={username_or_email}
              onChange={(e) => setUsernameOrEmail(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded mt-1"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700" style={{ fontFamily: 'Paytone One, sans-serif' }}>Password:</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded mt-1"
            />
          </div>
          {error && <p className="text-red-500 text-sm mb-4">{error}</p>}
          <button
            type="submit"
            className="w-full bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
          >
            Login
          </button>
        </form>
      </div>
    </div>
  );
};

export default Login;