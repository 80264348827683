import React, { useEffect, useState } from 'react';
import ClassProgress from './ClassProgress';
import axios from 'axios';
import { BACKEND_URL } from '../../../config';

const Profile = () => {
  const [profileData, setProfileData] = useState({
    userName: '',
    userEmail: '',
    userInstitution: '',
    userClasses: [],
    userSubject: '',
    classes: []
  });

  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        const response = await axios.get(`${BACKEND_URL}/backapp/${localStorage.getItem('userType')}/${localStorage.getItem('userId')}/`);
        setProfileData(response.data);
      } catch (error) {
        console.error('Error fetching profile data:', error);
      }
    };

    fetchProfileData();
  }, []);

  const { userName, userEmail, userInstitution, userClasses, userSubject, classes } = profileData;

  return (
    <div className="p-8">
      <div className="bg-blue-500 text-white p-6 rounded-lg mb-8">
        <h1 className="text-2xl font-bold mb-4">{userName}</h1>
        <p className="mb-2"><strong>Email:</strong> {userEmail}</p>
        <p className="mb-2"><strong>Institution:</strong> {userInstitution || 'N/A'}</p>
        <p className="mb-2"><strong>Classes:</strong> {userClasses.join(', ')}</p>
        <p className="mb-2"><strong>Subject:</strong> {userSubject.name}</p>
      </div>

      <div>
        <h2 className="text-xl font-bold mb-4">Mes classes</h2>
        {classes.map(classInfo => (
          <ClassProgress
            key={classInfo.id} // Utilisez une clé unique pour chaque élément
            className={classInfo.name} // Affichez les propriétés de l'objet
            avancement={classInfo.avancement}
            reussite={classInfo.reussite}
          />
        ))}
      </div>
    </div>
  );
};

export default Profile;