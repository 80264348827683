import { useEffect, useState } from "react"
import { useSearchParams, useNavigate, useLocation } from "react-router-dom"
import { Pencil, Sparkles, Trash2 } from 'lucide-react'
import { Button } from "../components/ui/button"
import { Card } from "../components/ui/card"
import { Input } from "../components/ui/input"
import axios from 'axios'
import { BACKEND_URL } from '../../../../config'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../components/ui/select"
import { toast, ToastContainer } from 'react-toastify'


interface Answer {
  answer: string
  isTrue: boolean
}

interface Question {
  id: number
  question: string
  answers: {
    A: Answer
    B: Answer
    C: Answer
    D: Answer
  }
  correct_answers: string[]
  notions: string[]
  themes: string[]
  chapter: string[]
  subject: number | null
}

interface Quiz {
  quiz_id: number
  name: string
  questions: Question[]
  theme: string
  chapter: string
  notion: string
}

interface Theme {
  id: number
  name: string
}

interface Chapter {
  id: number
  name: string
  theme: number
}

interface Notion {
  id: number
  name: string
  chapter: number[]
}

export function GeneratePage() {
  const [searchParams] = useSearchParams()
  const quizz_id = searchParams.get('id')
  const navigate = useNavigate()
  const [quiz, setQuiz] = useState<Quiz | null>(null)
  const [themes, setThemes] = useState<Theme[]>([])
  const [chaptersMap, setChaptersMap] = useState<Record<number, Chapter[]>>({})
  const [notionsMap, setNotionsMap] = useState<Record<number, Notion[]>>({})
  const isNewQuiz = searchParams.get('new') === 'true'
  const isAiGenerated = searchParams.get('aiGenerated') === 'true';

  useEffect(() => {
    if (isAiGenerated) {
      toast.success("Questions générées avec succès par l'IA", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  }, [isAiGenerated]);

  useEffect(() => {
    if (isNewQuiz) {
      toast.success("Évaluation créée avec succès", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  }, [isNewQuiz]);

  useEffect(() => {
    if (quizz_id) {
      fetchQuizData();
    } else {
      // Initialize empty quiz if no quizz_id
      setQuiz({
        quiz_id: 0,
        name: "",
        questions: [],
        theme: "",
        chapter: "",
        notion: ""
      });
    }

  }, [quizz_id]);


  useEffect(() => {
    fetchThemes()
  }, [])

  useEffect(() => {
    if (quiz?.questions) {
      quiz.questions.forEach(question => {
        if (question.themes[0]) {
          fetchChapters(question.themes[0].toString(), question.id);
        }
        if (question.chapter[0]) {
          fetchNotions(question.chapter[0].toString(), question.id);
        }
      });
    }
  }, [quiz]);

  const fetchThemes = async () => {
    try {
      const response = await axios.get(`${BACKEND_URL}/backapp/teacher/themes`, {
        params: {
          teacher_id: localStorage.getItem('userId'),
        },
      })
      setThemes(response.data.themes)
    } catch (error) {
      console.error('Error fetching themes:', error)
    }
  }

  const fetchChapters = async (themeId: string, questionId: number) => {
    try {
      const response = await axios.get(`${BACKEND_URL}/backapp/teacher/themes/chapters`, {
        params: {
          teacher_id: localStorage.getItem('userId'),
          theme_id: themeId,
        },
      })
      setChaptersMap(prev => ({
        ...prev,
        [questionId]: response.data.chapters
      }))
    } catch (error) {
      console.error('Error fetching chapters:', error)
    }
  }

  const fetchNotions = async (chapterId: string, questionId: number) => {
    try {
      const response = await axios.get(`${BACKEND_URL}/backapp/teacher/chapters/notions`, {
        params: {
          teacher_id: localStorage.getItem('userId'),
          chapter_id: chapterId,
        },
      })
      setNotionsMap(prev => ({
        ...prev,
        [questionId]: response.data.notions
      }))
    } catch (error) {
      console.error('Error fetching notions:', error)
    }
  }

  const handleThemeChange = (questionId: number, themeId: string) => {
    handleUpdateQuestion(questionId, {
      themes: [themeId], // Keep as string
      chapter: [], 
      notions: [], 
    });
    fetchChapters(themeId, questionId);
  };
  
  const handleChapterChange = (questionId: number, chapterId: string) => {
    handleUpdateQuestion(questionId, {
      chapter: [chapterId], // Keep as string
      notions: [], 
    });
    fetchNotions(chapterId, questionId);
  };
  
  const handleNotionChange = (questionId: number, notionId: string) => {
    handleUpdateQuestion(questionId, {
      notions: [notionId], // Keep as string
    });
  };



  const fetchQuizData = async () => {
    try {
      const response = await axios.get(`${BACKEND_URL}/backapp/teacher/retrieve_quiz_in_preperation`, {
        params: {
          teacher_id: localStorage.getItem('userId'),
          quiz_id: quizz_id
        }
      })
      console.log('Quiz fetched:', response.data)
      setQuiz(response.data)
    } catch (error) {
      console.error('Error fetching quiz:', error)
    }
  }

  const handleGenerateWithAI = () => {
    if (!quiz) return;
    navigate(`/evaluation/ai/${quiz.quiz_id}`, {
      state: {
        name: quiz.name,
        theme: quiz.theme,
        chapter: quiz.chapter,
        notion: quiz.notion,
        quiz_id: quiz.quiz_id
      }
    });
  };

  const handleCreateManually = () => {
    setQuiz(prev => {
      if (!prev) return prev;
      const tempId = prev.questions.length > 0 
      ? Math.max(...prev.questions.map(q => q.id)) + 1 
      : 1;

      return {
        ...prev,
        questions: [
          ...(prev.questions || []),
          {
            id: tempId,
            newQuestion: true,
            question: "",
            answers: {
              A: { answer: "", isTrue: false },
              B: { answer: "", isTrue: false },
              C: { answer: "", isTrue: false },
              D: { answer: "", isTrue: false }
            },
            correct_answers: [],
            notions: [],
            themes: [],
            chapter: [],
            subject: null 
          }
        ]
      };
    });
  };

  const handleUpdateQuestion = (questionId: number, updates: Partial<Question>) => {
    setQuiz((prev: Quiz | null) => {
      if (!prev) return prev;
      return {
        ...prev,
        questions: prev.questions.map((q: Question) =>
          q.id === questionId ? { ...q, ...updates } : q
        ),
      };
    });
  };
  
  const handleDeleteQuestion = (questionId: number | null) => {
    setQuiz((prev: Quiz | null) => {
      if (!prev) return prev;
      return {
        ...prev,
        questions: prev.questions.filter((q) => q.id !== questionId),
      };
    });
  };
  
  const handleSave = async () => {
    try {
      console.log('Saving quiz:', quiz);
      if (!quiz) return;
      const response = await axios.post(
        `${BACKEND_URL}/backapp/teacher/save_quiz_in_preperation`,
        {
          ...quiz,
          teacher_id: localStorage.getItem('userId'),
        }
      );
  
      if (response.status === 200 || response.status === 201) {
        console.log('Quiz saved successfully');
        navigate('/evaluations', { state: { showSaveSuccess: true } });
      } else {
        toast.error("Erreur lors de l'enregistrement de l'évaluation", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        console.error('Error saving quiz:', response.statusText);
      }
    } catch (error) {
      toast.error("Erreur lors de l'enregistrement de l'évaluation", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      console.error('Error saving quiz:', error);
    }
  };

  return (
    <div className="container max-w-4xl mx-auto py-8">
      <ToastContainer />
      <Card className="bg-[#e7f2ff] p-6 mb-8">
        <div className="space-y-4">
          <h1 className="text-3xl font-bold text-[#010f44]">{quiz?.name || "Nouvelle évaluation"}</h1>
  
          <div className="flex flex-wrap gap-2">
            <span className="bg-white px-3 py-1 rounded-full text-sm">
              Thème - {quiz?.theme || "Non défini"}
            </span>
            <span className="bg-white px-3 py-1 rounded-full text-sm">
              Chapitre - {quiz?.chapter || "Non défini"}
            </span>
            <span className="bg-white px-3 py-1 rounded-full text-sm">
              Notion - {quiz?.notion || "Non défini"}
            </span>
            {(quiz?.questions?.length || 0) > 0 && (
              <span className="bg-white px-3 py-1 rounded-full text-sm">
                Nombre de questions: {quiz?.questions?.length}
              </span>
            )}
          </div>
        </div>
      </Card>
  
      <div className="text-center mb-8">
        <p className="text-[#010f44] mb-4">
          {quizz_id ? "Modifier votre évaluation" : "Évaluation créée ! À toi de la remplir avec tes questions !"}
        </p>
        <div className="flex gap-4 justify-center">
          <Button
            onClick={handleGenerateWithAI}
            className="bg-[#1550a7] hover:bg-[#1550a7]/90"
          >
            <Sparkles className="mr-2 h-4 w-4" />
            Générer avec l'IA
          </Button>
          <Button
            variant="outline"
            onClick={handleCreateManually}
            className="border-[#1550a7] text-[#1550a7]"
          >
            <Pencil className="mr-2 h-4 w-4" />
            Créer soi-même
          </Button>
        </div>
      </div>
  
      <div className="space-y-6">
        {quiz?.questions?.map((question, index) => (
          <Card key={question.id} className="p-6">
            <div className="flex justify-between items-start mb-4">
              <h2 className="text-xl font-bold text-[#010f44]">
                Question {index + 1}
              </h2>
              <div className="flex gap-2">
                <Button
                  variant="ghost"
                  size="icon"
                  onClick={() => handleDeleteQuestion(question.id)}
                  className="text-red-500 hover:text-red-600 hover:bg-red-50"
                >
                  <Trash2 className="h-4 w-4" />
                </Button>
              </div>
            </div>
  
            <div className="space-y-4">
              <Input
                value={question.question}
                onChange={(e) =>
                  handleUpdateQuestion(question.id, { question: e.target.value })
                }
                placeholder="Intitulé de la question"
                className="font-medium"
              />

              {/* Theme/Chapter/Notion selectors */}
              <div className="flex flex-wrap gap-4 mt-4 mb-2">
                <div className="flex-1">
                  <Select
                    value={question.themes[0]?.toString() || quiz.theme}
                    onValueChange={(value) => handleThemeChange(question.id, value)}
                  >
                    <SelectTrigger>
                      <SelectValue placeholder={quiz.theme || "Sélectionner un thème"} />
                    </SelectTrigger>
                    <SelectContent>
                      {themes.map((theme) => (
                        <SelectItem key={theme.id} value={theme.id.toString()}>
                          {theme.name}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </div>
                <div className="flex-1">
                  <Select
                    value={question.chapter[0]?.toString() || quiz.chapter}
                    onValueChange={(value) => handleChapterChange(question.id, value)}
                  >
                    <SelectTrigger>
                      <SelectValue placeholder={quiz.chapter || "Sélectionner un chapitre"} />
                    </SelectTrigger>
                    <SelectContent>
                      {chaptersMap[question.id]?.map((chapter) => (
                        <SelectItem key={chapter.id} value={chapter.id.toString()}>
                          {chapter.name}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </div>

                <div className="flex-1">
                  <Select
                    value={question.notions[0]?.toString() || quiz.notion}
                    onValueChange={(value) => handleNotionChange(question.id, value)}
                  >
                    <SelectTrigger>
                      <SelectValue placeholder={quiz.notion || "Sélectionner une notion"} />
                    </SelectTrigger>
                    <SelectContent>
                      {notionsMap[question.id]?.map((notion) => (
                        <SelectItem key={notion.id} value={notion.id.toString()}>
                          {notion.name}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </div>
              </div>
  
              {Object.entries(question.answers).map(([key, answer]) => (
                <div key={key} className="flex items-center gap-4">
                  <div className={`w-8 h-8 rounded-full flex items-center justify-center text-white ${
                    key === 'A' ? "bg-[#8b5cf6]" :
                    key === 'B' ? "bg-[#10b981]" :
                    key === 'C' ? "bg-[#f59e0b]" :
                    "bg-[#ef4444]"
                  }`}>
                    {key}
                  </div>
                  <div className={`flex-1 ${answer.isTrue ? "bg-[#d4edd6]" : "bg-[#f3f9ff]"} rounded-md`}>
                    <Input
                      value={answer.answer}
                      onChange={(e) =>
                        handleUpdateQuestion(question.id, {
                          answers: {
                            ...question.answers,
                            [key]: { ...answer, answer: e.target.value }
                          }
                        })
                      }
                      placeholder={`Réponse ${key}`}
                      className="border-0 bg-transparent"
                    />
                  </div>
                  <input
                    type="checkbox"
                    checked={answer.isTrue}
                    onChange={(e) =>
                      handleUpdateQuestion(question.id, {
                        answers: {
                          ...question.answers,
                          [key]: { ...answer, isTrue: e.target.checked }
                        }
                      })
                    }
                    className="w-5 h-5 rounded border-gray-300"
                  />
                </div>
              ))}
            </div>
          </Card>
        ))}
      </div>
  
      {(quiz?.questions?.length || 0) > 0 && (
        <div className="mt-8 flex justify-end">
          <Button
            onClick={handleSave}
            className="bg-[#1550a7] hover:bg-[#1550a7]/90"
          >
            Enregistrer
          </Button>
        </div>
      )}
    </div>
  );
}