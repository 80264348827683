import { useState, useMemo } from "react"
import { useNavigate, useLocation } from "react-router-dom"
import { Card } from "../../components/ui/card"
import { Input } from "../../components/ui/input"
import { Button } from "../../components/ui/button"
import { FileUpload } from "../../components/ui/file-upload"
import { LoadingOverlay } from "../../components/ui/loading-overlay"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../components/ui/select"
import { BACKEND_URL } from "../../../../../config"
import axios from "axios"
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import {X} from 'lucide-react';

interface QuizData {
  name: string
  theme: string
  chapter: string
  notion: string
  quiz_id: number
}

export default function AIGenerationPage() {
  const navigate = useNavigate()
  const location = useLocation()
  const quizData = location.state as QuizData

  const [file, setFile] = useState<File | null>(null)
  const [questionCount, setQuestionCount] = useState("5")
  const [additionalInstructions, setAdditionalInstructions] = useState("")
  const [isGenerating, setIsGenerating] = useState(false)

  const pdfPreview = useMemo(() => {
    if (!file) return null;
    
    return (
      <div className="max-w-2xl mx-auto border-2 border-[#e5e9ef] rounded-lg overflow-hidden h-[300px]">
        <div className="relative h-full">
          <Worker workerUrl={`https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js`}>
            <Viewer fileUrl={URL.createObjectURL(file)} />
          </Worker>
          <button 
            onClick={() => setFile(null)}
            className="absolute top-2 right-2 p-1 bg-white rounded-full shadow-md hover:bg-gray-100"
          >
            <X size={16} />
          </button>
        </div>
      </div>
    );
  }, [file]);

  const handleGenerate = async () => {
    setIsGenerating(true);
    try {
      const formData = new FormData();
      formData.append("language", "fr");
      formData.append("number_of_questions", questionCount);
      formData.append("notion", quizData.notion);
      formData.append("chapter", quizData.chapter);
      formData.append("theme", quizData.theme);
      formData.append("difficulty_level", "medium");
      formData.append("quiz_id", quizData.quiz_id.toString());
  
      if (file) {
        if (file.size > 10 * 1024 * 1024) {
          toast.error("Le fichier ne doit pas dépasser 10MB");
          return;
        }
        formData.append("file", file);
      }

      if (additionalInstructions) {
        formData.append("instructions", additionalInstructions);
      }

      const response = await axios.post(
        `${BACKEND_URL}/backapp/teacher/generate_questions/`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
  
      if (response.status === 200) {
        navigate(`/evaluation/generate?id=${quizData.quiz_id}&aiGenerated=true`);
      } else {
        toast.error("Le service de génération de question par IA n'est pas disponible 😅", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }

    } catch (error) {
      toast.error("Le service de génération de question par IA n'est pas disponible 😅", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      console.error("Error generating questions:", error);
    } finally {
      setIsGenerating(false);
    }
  };

  const handleFileSelect = async (file: File) => {
    // Check file type
    if (file.type !== 'application/pdf') {
      toast.error("Veuillez soumettre uniquement des fichiers PDF", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      return;
    }
  
    try {
      // Load PDF.js
      const pdfjsLib = await import('pdfjs-dist');
      pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.min.js`;
  
      // Load the PDF file
      const arrayBuffer = await file.arrayBuffer();
      const pdf = await pdfjsLib.getDocument({ data: arrayBuffer }).promise;
  
      // Check number of pages
      if (pdf.numPages > 2) {
        toast.error("Le document ne doit pas dépasser 2 pages", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        return;
      }
  
      // If all validations pass, set the file
      setFile(file);
    } catch (error) {
      console.error('Error checking PDF:', error);
      toast.error("Erreur lors de la vérification du fichier", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  return (
    <>
      <ToastContainer />
      {isGenerating && <LoadingOverlay />}
      <div className="container max-w-4xl mx-auto py-8">
        <Card className="bg-[#e7f2ff] p-6 mb-8">
          <div className="space-y-4">
            <h1 className="text-3xl font-bold text-[#010f44]">
              {quizData?.name || "Nouvelle évaluation"}
            </h1>
            <p className="text-sm text-gray-500">
              {new Date().toLocaleDateString()}
            </p>

            <div className="flex flex-wrap gap-2">
              <span className="bg-white px-3 py-1 rounded-full text-sm">
                Thème - {quizData?.theme || "Non défini"}
              </span>
              <span className="bg-white px-3 py-1 rounded-full text-sm">
                Chapitre - {quizData?.chapter || "Non défini"}
              </span>
              <span className="bg-white px-3 py-1 rounded-full text-sm">
                Notion - {quizData?.notion || "Non défini"}
              </span>
            </div>
          </div>
        </Card>

        <Card className="border border-[#e5e9ef] bg-[#fbf9ff] p-8">
          <div className="space-y-8">
            <div className="text-center space-y-2">
              <h2 className="text-2xl font-bold text-[#010f44]">
                À partir d'un document ou d'une indication
              </h2>
              <p className="text-sm text-gray-600">
                Le document doit être au format PDF et ne pas dépasser 2 pages
              </p>
            </div>

            {file ? pdfPreview : (
              <FileUpload
                onFileSelect={handleFileSelect}
                className="max-w-2xl mx-auto"
              />
            )}

            <div className="grid gap-6 max-w-2xl mx-auto">
              <div className="space-y-2">
                <label className="text-sm font-medium text-gray-700">
                  Nombre de questions
                </label>
                <Select
                  value={questionCount}
                  onValueChange={setQuestionCount}
                >
                  <SelectTrigger className="w-32">
                    <SelectValue placeholder="1" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="1">1</SelectItem>
                    <SelectItem value="3">3</SelectItem>
                    <SelectItem value="5">5</SelectItem>
                    <SelectItem value="10">10</SelectItem>
                  </SelectContent>
                </Select>
              </div>

              <div className="space-y-2">
                <label className="text-sm font-medium text-gray-700">
                  Indications additionnelles (optionnel)
                </label>
                <Input
                  placeholder="Pose seulement des questions sur la première page du document..."
                  value={additionalInstructions}
                  onChange={(e) => setAdditionalInstructions(e.target.value)}
                />
              </div>

              <div className="flex justify-center">
                <Button
                  onClick={handleGenerate}
                  disabled={(!file && !additionalInstructions) || isGenerating}
                  className="bg-[#b084f8] hover:bg-[#b084f8]/90 text-white px-8"
                >
                  {isGenerating ? "Génération..." : "Générer"}
                </Button>
              </div>
            </div>
          </div>
        </Card>
      </div>
    </>
  )
}

