import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserCircle, StickyNote, HelpCircle, LogOut, FolderClosed, BookOpen } from 'lucide-react';
import logo from '../assets/logo.svg';
import LogoutModal from './Logout'; 
import './Sidebar.css'

const Sidebar = ({ userType, onLogout}) => {
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const [activeItem, setActiveItem] = useState('profile');
  const [userName, setUserName] = useState('');
  const [userClassOrInstitution, setUserClassOrInstitution] = useState('');
  const [userProfilePic, setUserProfilePic] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    // Read information from localStorage
    const storedUserName = `${localStorage.getItem('userFirstName')} ${localStorage.getItem('userLastName')}`;
    const storedUserClassOrInstitution = localStorage.getItem('schoolName');
    const storedUserProfilePic = ''; // Add logic to retrieve profile picture if available

    setUserName(storedUserName);
    setUserClassOrInstitution(storedUserClassOrInstitution);
    setUserProfilePic(storedUserProfilePic);
  }, []);

  const menuItems = userType === 'student'
    ? [
        { key: 'profile', icon: <UserCircle size={20} />, label: 'Mon compte', path: '/profile' },
        { key: 'evaluations', icon: <StickyNote size={20} />, label: 'Mes évaluations', path: '/evaluations' },
      ]
    : [
        { key: 'profile', icon: <UserCircle size={20} />, label: 'Mon compte', path: '/profile' },
        { key: 'evaluations', icon: <StickyNote size={20} />, label: 'Mes évaluations', path: '/evaluations' },
        { key: 'classes', icon: <BookOpen size={20} />, label: 'Mes élèves', path: '/classes' },
      ];

  const getInitials = (name) => {
    const initials = name.split(' ').map((n) => n[0]).join('');
    return initials.toUpperCase();
  };

  const handleLogoutClick = (event) => {
    event.preventDefault();
    setShowLogoutModal(true);
  };

  const handleConfirmLogout = () => {
    localStorage.removeItem('userType');
    onLogout();
    setShowLogoutModal(false);
    navigate('/login');
    
  };

  const handleCancelLogout = () => {
    setShowLogoutModal(false);
  };

  const handleMenuItemClick = (path) => {
    navigate(path); // Navigate to the specified path
  };

  const handleItemClick = (path, key) => {
    setActiveItem(key);
    navigate(path);
  };

  return (
    <>
    <div className="sidebar">
      <div>
        <div className="p-4 h-16 flex items-center justify-center bg-white">
          <img src={logo} alt="Company Logo" className="h-12" /> {/* Use the imported SVG */}
        </div>
        <div className="p-4 flex items-center">
          {userProfilePic ? (
            <img src={userProfilePic} alt="Profile" className="w-12 h-12 rounded-full" />
          ) : (
            <div className="w-12 h-12 rounded-full bg-gray-300 flex items-center justify-center text-xl font-bold text-gray-700">
              {getInitials(userName)}
            </div>
          )}
          <div className="ml-4">
            <p className='name'>{userName}</p>
            <div className="text">{userClassOrInstitution}</div>
          </div>
        </div>
        <nav>
          {menuItems.map((item) => (
            <div
              key={item.key}
              className="flex items-center p-4 hover:bg-gray-200 text-gray-800 cursor-pointer"
              onClick={() => handleMenuItemClick(item.path)}
            >
              {item.icon}
              <span className="ml-4">{item.label}</span>
            </div>
          ))}
          <div
            className="flex items-center p-4 hover:bg-gray-200 text-gray-800 cursor-pointer"
            onClick={() => handleMenuItemClick('/help')}
          >
            <HelpCircle size={20} />
            <span className="ml-4">Aide et Recommendation</span>
          </div>
        </nav>
      </div>
      <div className="p-4">
        <div className="flex items-center p-4 hover:bg-gray-200 text-gray-800 cursor-pointer" onClick={handleLogoutClick}>
          <LogOut size={20} />
          <span className="ml-4">Se déconnecter</span>
        </div>
      </div>
      {showLogoutModal && (
        <LogoutModal
          show={showLogoutModal}
          onConfirm={handleConfirmLogout}
          onCancel={handleCancelLogout}
        />
      )}
    </div>


    {/* Bottom Navbar for smaller screens */}
    <div className="mobile-navbar">
        {menuItems.map((item) => (
            <button
                key={item.key}
                onClick={() => handleItemClick(item.path, item.key)}
                className={`flex flex-col items-center ${
                    activeItem === item.key ? 'text-blue-500' : 'text-gray-800'
                }`}
            >
                {item.icon}
                <span className="text-xs">{item.label}</span>
            </button>
        ))}
        <button
            onClick={() => handleItemClick('/help', 'help')}
            className={`flex flex-col items-center ${
                activeItem === 'help' ? 'text-blue-500' : 'text-gray-800'
            }`}
        >
            <HelpCircle size={20} />
            <span className="text-xs">Aide</span>
        </button>
        <button
            onClick={handleLogoutClick}
            className="flex flex-col items-center text-gray-800"
        >
            <LogOut size={20} />
            <span className="text-xs">Déconnexion</span>
        </button>
    </div>
    </>
  );
};

export default Sidebar;