import { useParams } from 'react-router-dom';
import { Check, X } from 'lucide-react'
import { Card } from '../components/ui/card';

interface StudentResult {
  id: string;
  name: string;
  successRate: number;
  timeSpent: string;
  score: number;
  maxScore: number;
  answers: {
    questionId: number;
    selectedAnswers: string[];  // Array of selected answer keys (e.g. ["A", "B"])
    isCorrect: boolean;
    correctAnswers: string[];  // Array of correct answer keys
  }[];
}

interface EvaluationResult {
  id: string;
  title: string;
  date: string;
  theme: string;
  chapter: string;
  notion: string;
  difficulty: string;
  duration: string;
  questions: {
    id: number;
    question: string;
    answers: {
      text: string;
      isCorrect: boolean;
    }[];
  }[];
}

interface StudentResultViewProps {
  student: StudentResult;
  evaluation: EvaluationResult;
}

export function StudentResultView({ student, evaluation }: StudentResultViewProps) {
  return (
    <div className="space-y-8">
      <Card className="p-6 bg-[#e7f2ff]">
        <div className="flex flex-col md:flex-row md:items-center justify-between gap-4">
          <div>
            <h2 className="text-2xl font-bold text-[#010f44]">{student.name}</h2>
          </div>
          <div className="flex items-center gap-8">
            <div className="space-y-2">
              <div className="text-sm text-gray-600">Réussite</div>
              <div className="flex items-center gap-4">
                <div className="w-32 h-6 bg-white rounded-full overflow-hidden">
                  <div
                    className="h-full bg-[#fee095]"
                    style={{ width: `${student.successRate}%` }}
                  />
                </div>
                <span className="text-sm font-medium">{student.successRate}%</span>
              </div>
            </div>
          </div>
        </div>
      </Card>

      <div className="space-y-6">
      {evaluation.questions.map((question, index) => {
        const studentAnswer = student.answers.find(a => a.questionId === question.id);
        return (
          <Card key={question.id} className="p-6">
            <div className="space-y-6">
              <div>
                <h3 className="text-xl font-bold text-[#010f44] mb-2">
                  Question {index + 1}
                </h3>
                <p className="text-gray-800">{question.question}</p>
              </div>

              <div className="space-y-4">
                {question.answers.map((answer, ansIndex) => {
                  const key = String.fromCharCode(65 + ansIndex); // Convert index to A, B, C, D
                  const isSelected = studentAnswer?.selectedAnswers.includes(key);
                  const isCorrect = answer.isCorrect;
                  
                  return (
                    <div key={key} className="flex items-center gap-4">
                      <div className={`w-8 h-8 rounded-full flex items-center justify-center text-white ${
                        key === 'A' ? "bg-[#caadfa]" :
                        key === 'B' ? "bg-[#73c57b]" :
                        key === 'C' ? "bg-[#fed061]" :
                                    "bg-[#ec6963]"
                      }`}>
                        {key}
                      </div>
                      <div className={`flex-1 p-3 rounded-md ${
                        isSelected
                          ? isCorrect
                            ? "bg-[#d4edd6]"
                            : "bg-[#f9d1cf]"
                          : isCorrect
                            ? "bg-[#f3f9ff]"
                            : "bg-white"
                      }`}>
                        <div className="flex justify-between items-center">
                          <span>{answer.text}</span>
                          {isSelected && (
                            isCorrect ? (
                              <Check className="h-5 w-5 text-[#73c57b]" />
                            ) : (
                              <X className="h-5 w-5 text-[#ec6963]" />
                            )
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </Card>
        );
      })}
      </div>
    </div>
  );
}

