// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.progress-bar-container {
    width: 100%;            
    position: relative;
    background-color: white;
    border-radius: 0.5rem;   
    box-sizing: border-box;     
    height: 1rem;            
    border: 1px solid #d1d5db;
    overflow: hidden;           
}

.progress-bar-inside-bar {
    border-radius: 0.5rem;   
    height: 100%;               
    transition: width 0.3s ease;
}

.percentage-text {
    position: absolute;
    right: 0.5rem;
    top: 50%;
    transform: translateY(-50%);
    font-size: 0.75rem;
    color: var(--color-subtext);
    z-index: 1;
}`, "",{"version":3,"sources":["webpack://./src/components/Student/components/ProgressBar.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,kBAAkB;IAClB,uBAAuB;IACvB,qBAAqB;IACrB,sBAAsB;IACtB,YAAY;IACZ,yBAAyB;IACzB,gBAAgB;AACpB;;AAEA;IACI,qBAAqB;IACrB,YAAY;IACZ,2BAA2B;AAC/B;;AAEA;IACI,kBAAkB;IAClB,aAAa;IACb,QAAQ;IACR,2BAA2B;IAC3B,kBAAkB;IAClB,2BAA2B;IAC3B,UAAU;AACd","sourcesContent":[".progress-bar-container {\n    width: 100%;            \n    position: relative;\n    background-color: white;\n    border-radius: 0.5rem;   \n    box-sizing: border-box;     \n    height: 1rem;            \n    border: 1px solid #d1d5db;\n    overflow: hidden;           \n}\n\n.progress-bar-inside-bar {\n    border-radius: 0.5rem;   \n    height: 100%;               \n    transition: width 0.3s ease;\n}\n\n.percentage-text {\n    position: absolute;\n    right: 0.5rem;\n    top: 50%;\n    transform: translateY(-50%);\n    font-size: 0.75rem;\n    color: var(--color-subtext);\n    z-index: 1;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
