import React, { useState, useEffect } from 'react';
import axios from 'axios';
import StudentDetailsPage from './StudentDetailsPage';
import './Classes.css';
import ProgressBar from '../../ProgressBar';
import '../.././Input.css';
import { BACKEND_URL } from '../../../config';
import {Zap} from 'lucide-react';

const Classes = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [classesData, setClassesData] = useState([]);

  useEffect(() => {
    const fetchStudentsData = async () => {
      const teacherId = localStorage.getItem('userId');
      try {
        const response = await axios.get(`${BACKEND_URL}/backapp/teacher/students_of_teacher`, {
          params: {
            teacher_id: teacherId
          }
        });
        setClassesData(response.data);
      } catch (error) {
        console.error('Error fetching students data:', error);
      }
    };

    fetchStudentsData();
  }, []);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleStudentClick = (student) => {
    setSelectedStudent(student);
  };

  const handleBackToClasses = () => {
    setSelectedStudent(null);
  };

  const getProgressBarColor = (score) => {
    if (score > 60) return 'var(--color-success)';
    if (score > 30) return 'var(--color-warning)';
    return 'var(--color-danger)';
  };

  const filteredClasses = classesData.map(classData => ({
    ...classData,
    students: classData.students.filter(student =>
      `${student.first_name} ${student.last_name}`.toLowerCase().includes(searchTerm.toLowerCase())
    )
  })).filter(classData => classData.students.length > 0);

  return (
    <div className="p-8">
      {selectedStudent ? (
        <StudentDetailsPage student={selectedStudent} onBack={handleBackToClasses} />
      ) : (
        <>
          <h1>Mes élèves</h1>
          <input
            type="text"
            placeholder="Rechercher un élève"
            value={searchTerm}
            onChange={handleSearchChange}
            className="input"
          />
          
          {filteredClasses.map(classData => (
            <div key={classData.id} className="class-students">
              <h2>{classData.name}</h2>
              {classData.students.map(student => (
                <div
                  key={student.id}
                  className="student-card"
                  onClick={() => handleStudentClick(student)}
                >
                  <div className="student-main-info">
                    <p className="big-text">{`${student.first_name} ${student.last_name}`}</p>
                    <p className="subtext">{student.school}</p>
                  </div>
                  <div className="results-info">
                    <ProgressBar
                      label="Réussite"
                      percentage={student.average_score || 0}
                      color={getProgressBarColor(student.average_score || 0)}
                    />
                    <div className="attempts-nb">
                      <p>{student.attempted_quizzes}/{student.total_quizzes}</p>
                      <Zap size={16}/>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ))}
        </>
      )}
    </div>
  );
};

export default Classes;