import * as React from "react"
import { useDropzone } from "react-dropzone"
import { cn } from "../../lib/utils"

interface FileUploadProps extends React.HTMLAttributes<HTMLDivElement> {
  onFileSelect: (file: File) => void
}

export function FileUpload({ onFileSelect, className, ...props }: FileUploadProps) {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      'application/pdf': ['.pdf']
    },
    maxFiles: 1,
    onDrop: (acceptedFiles) => {
      if (acceptedFiles?.[0]) {
        onFileSelect(acceptedFiles[0])
      }
    }
  })

  return (
    <div
      {...getRootProps()}
      className={cn(
        "border-2 border-dashed rounded-lg p-8 text-center cursor-pointer transition-colors",
        isDragActive ? "border-[#b084f8] bg-[#b084f8]/5" : "border-[#e5e9ef]",
        className
      )}
      {...props}
    >
      <input {...getInputProps()} />
      <div className="text-gray-600">
        <span className="text-[#b084f8]">+ </span>
        {isDragActive ? "Déposer le fichier ici" : "Déposer ou ajouter des fichiers"}
      </div>
    </div>
  )
}

